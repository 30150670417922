* {
    font-family: 'Poppins', sans-serif;
    color: #143840;
}

body {
    background-image: url('img/tinified/hero.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.hide {
    display: none;
}

.nav {
    background-color: transparent !important;
    max-width: 75%; /* should match the width of your website content */
    display: flex;
    align-items: center; /* center each of our links vertically */
    margin: auto; /* center all our content horizontally when we exceed that max-width */
}

.logo {
    margin-right: auto !important; /* push all our links to the right side, leaving the logo on the left */
}

.modal-content {
    text-align: center;
}

.modal-header {
    background-color: #7d8c8b;
    color: #143840;
    border-bottom: none !important;
}

.modal-body {
    background-color: #7d8c8b;
    color: #143840;
}

.modal-body a {
    color: #f2d2b6;
}

.links {
    display: inline-block !important;
    color: #143840;
}

.dropdown-link-container > a {
    margin-left: 20px; /* space out all our links */
}

.mobile-dropdown-toggle {
    display: none; /* hide our hamburger button until we're on mobile */
}

.arrowBounce {
    position: relative;
    animation: bounce 2s infinite;
}

.awsCert {
    max-width: 5%;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.arrowScrollDown {
    animation: arrowAnimation 1.5s infinite ease-out;
}

@keyframes arrowAnimation {
    0% {
        transform: translateY(-20px);
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    60% {
        opacity: 1;
    }
    to {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        opacity: 0;
    }
}

.landingBlock {
    margin-top: 5%;
    margin-bottom: 15%;
}

.landingMargin {
    margin-bottom: 3rem;
    font-family: 'Permanent Marker', cursive;
}

.landingBlock h1 {
    font-size: 8rem;
    color: #143840;
}

.landingBlock h2 {
    font-size: 3rem;
    color: #143840;
}

.bioBlock {
    margin-top: 15%;
    margin-bottom: 12%;
}

.bioBlock h1 {
    font-size: 3rem;
}

.bioBlock p {
    font-size: 1.5rem;
}

.stackBlock {
    margin-top: 12%;
    margin-bottom: 15%;
}

.stackCol {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stackUl {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.jumbotron {
    background: transparent;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    height: 50%;
}

.filterIcon {
    cursor: pointer;
    max-height: 4rem;
    margin: 1rem;
    justify-content: center;
}

.magnifyBtn {
    padding: 12px 24px;
    background-color: hsl(222, 100%, 95%);
    border-radius: 6px;
    transition: transform 250ms ease-in-out;
}

.magnifyBtn:hover {
    transform: scale(1.1);
}

.magnifyBtn:active {
    transform: scale(0.9);
}

.recentProjects {
    font-family: 'Permanent Marker', cursive;
}

.projectContainer {
    overflow: auto;
    z-index: 2;
}

.compactCard {
    display: inline-flex;
    text-align: center;
    width: 40%;
    margin: 1%;
    cursor: pointer;
}

.expandedCard {
    display: inline-flex;
    text-align: center;
    height: auto;
    width: 75%;
    margin: 1%;
    justify-content: center;
    cursor: pointer;
}

.projectDetail {
    background-color: #7d8c8b;
    border-radius: 1rem;
    padding: 2rem;
}

.credits a {
    color: #7d8c8b;
    text-decoration: underline;
}

.credits,
.credits p {
    position: fixed;
    height: 1rem;
    bottom: 0;
    width: 100%;
    color: #f2d2b6 !important;
    text-align: center !important;
    justify-content: center;
    z-index: -1;
    background-color: rgba(67, 101, 115, 0.25);
}

/* Media Queries */
@media (max-width: 768px) {
    /* arbitrary breakpoint, around the size of a tablet */
    .logo,
    .mobile-dropdown-toggle {
        z-index: 4;
    }

    .nav {
        padding-top: 2.5rem;
    }

    .mobile-dropdown-toggle {
        display: initial; /* override that display: none attribute from before */
        background-color: transparent;
        border: none;
    }

    .dropdown-link-container {
        /* first, make our dropdown cover the screen */
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        /* fix nav height on mobile safari, where 100vh is a little off */
        height: -webkit-fill-available;

        /* add margins and padding to taste */
        margin: 0;
        padding-left: 7vw;
        padding-right: 7vw;

        backdrop-filter: blur(4px);
        z-index: 3; /* we're gonna avoid using -1 here, since it could position our navbar below other content on the page as well! */

        opacity: 0; /* fade out */
        transform: translateY(-100%); /* move out of view */
        transition: transform 0.2s, opacity 0.2s; /* transition these smoothly */
    }

    .dropdown-links {
        /* then, arrange our links top to bottom */
        display: flex;
        flex-direction: column;
        /* center links vertically and horizontally.
           this means our links will line up with the rightward hamburger button */
        justify-content: center;
        align-items: center;
        margin-top: 25%;
        width: 100%;
        background-color: #f2d2b6;
        padding: 2rem;
    }

    .links {
        font-size: 2rem;
        font-weight: bold;
        color: #143840;
    }

    nav.dropdown-opened > .dropdown-link-container {
        opacity: 1; /* fade in */
        transform: translateY(0); /* move into view */
    }

    .landingBlock {
        margin-bottom: 5%;
    }

    .landingMargin {
        margin-bottom: 1rem;
    }

    .bioBlock {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .stackBlock {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .headshot {
        max-width: 40%;
    }

    .projectDetail {
        background-color: #7d8c8b;
        border-radius: 1rem;
        padding: 0;
        justify-content: center;
    }

    .mediaQuery {
        width: 100% !important;
        background-color: #f2d2b6;
    }

    .hideMobile {
        display: none;
    }

    body {
        background-image: none;
        color: #143840;
        background-color: #f2d2b6 !important;
    }
}

#nav-icon1 {
    width: 3rem;
    height: auto;
    position: relative;
    margin: 2rem auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

#nav-icon1 span {
    display: block;
    position: absolute;
    height: 9px;
    width: 100%;
    background: #143840;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
    top: 0px;
}

#nav-icon1 span:nth-child(2) {
    top: 18px;
}

#nav-icon1 span:nth-child(3) {
    top: 36px;
}

#nav-icon1.dropdown-opened span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

#nav-icon1.dropdown-opened span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

#nav-icon1.dropdown-opened span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
